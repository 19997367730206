.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 100vh;
  /* width: 100vw; */
  color: white;
}

.App-link {
  color: #61dafb;
}

.mq-f {
  margin: 0em !important;
}

.mq-text-mode {
  font-family: serif !important;
  font-style: italic !important;
}

.Collapsible {
  margin-bottom: 1em;
}
.mq-math-mode * {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-style: normal !important;
  font-size: medium !important;
}

.mq-editable-field{
  padding: 1em !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #ebebeb;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: rgb(4 120 87);
}

@keyframes dotAnimation {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.loading-dot {
  animation: dotAnimation 1.5s infinite;
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dot:nth-child(3) {
  animation-delay: 1s;
}