@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  print-color-adjust: exact;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  width: 210mm;
  min-height: 297mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.bg-deepTeal {
  background-color: #047857;
}

.subpage {
  padding: 1cm;
  min-height: 297mm;
}

.question-prev td {
  border: 1px solid black;
  padding: 2px 5px;
}

.dcg-wrapper .dcg-expressions-branding {
  display: none;
}

.dcg-zoom-container {
  margin-top: 41px;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
    margin: 0em;
    padding: 0em;
  }

  .zoomWrapper {
    padding-top: 0em;
    scale: 1;
    margin: 0em;
    
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    background-color: white;
  }
}

.remove-input-arrows {
  -moz-appearance: textfield;
}

.remove-input-arrows::-webkit-inner-spin-button,
.remove-input-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.prev-html .ql-align-center img,
.prev-html .ql-align-center-table {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.prev-html .ql-align-right img {
    display: block;
    margin-left: auto;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

.hide-input-arrows::-webkit-outer-spin-button,
.hide-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-input-arrows[type=number] {
  -moz-appearance: textfield;
}

/* Fixes rendering of the  katex expression "\overarc" for mathquil formulas  */
.ql-formula[data-value^='\\overarc'] {
  border-top: 1px solid black;
  border-top-right-radius: 50% 0.3em;
  border-top-left-radius: 50% 0.3em;
  margin-top: 1px;
  padding-top: 0.15em;
}
.ql-formula[data-value^='\\overarc'] .text {
  display: none;
}

/* Fixes rendering of the katex expression "\parallelogram" for mathquil formulas  */
.ql-formula[data-value^='\\parallelogram'] .text {
  display: none;
}
.ql-formula[data-value^='\\parallelogram'] .katex-html .strut::after {
  content: '▱';
  vertical-align: baseline;
  display: inline-block;
  position: relative;
  top: -4px;
}

.bg-red {
  background-color: #ff407d;
}

.bg-yellow {
  background-color: #f5dd61;
}

.bg-blue {
  background-color: #59d5e0;
}

.select-none {
  user-select: none;
}